import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../atoms/AppAtome";
import { Button, message, notification } from "antd";
import { useState } from "react";
import { CheckOutlined,WarningOutlined } from "@ant-design/icons";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";


export default function CreateUser(){
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(false);
    const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${cookies.seduc_token}`
	  };
	
	const config = {
		headers: headers,
	  };

    const handleSubmit = () => {

        if(nom =='' || prenom =='' || email =='' || tel =='' || password ==''){

            // on natifie 
            notification.open({
                message:'Erreur formulaire',
                description: 'un ou plusieurs champs sont manquants',
                placement:'bottomLeft',
                icon: <WarningOutlined />,
                color: 'red',
            })
            
        }else{
            setLoading(true);
            axios.post(APP_URL+'admin/save-user',{
                'nom':nom,
                'prenom':prenom,
                'email':email,
                'tel':tel,
                'password':password2
            },config).then((response) => {
                setLoading(false);
                console.log(response.data);

                notification.open({
                    message:(
                        <div style={{ color: 'white' }}>
                          {response.data.msg}
                        </div>
                      ),
                    description: '',
                    placement:'bottomLeft',
                    icon: <WarningOutlined />,
                    style: {
                        backgroundColor: response.data.color, // Couleur de fond personnalisée
                        color:'white'
                        // Autres styles personnalisés...
                      },
                })

            }).catch((error) => {
                console.log(error);
            })
        }
    }

    return (
        <>
    <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">formulaire</li>
     </ol>

     <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-table"></i> Creer un nouvel utilisateur maintenant  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <Link to={'javascript:void(0)'} onClick={() => setMainView('ecoles')} className="btn btn-primary">Liste des utilisateur <span className="fa fa-list"></span></Link>
        </div>

        <div class="card-body">
          <div class="table-responsive">
                <label>Nom</label>
                <input type="text" className="form-control" value={nom} onChange={(e) => setNom(e.target.value)} placeholder="Nom" />
                <label>Prenom</label>
                <input type="text" className="form-control" value={prenom} onChange={(e) => setPrenom(e.target.value)} placeholder="Prenom" />
                <label>Email</label>
                <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                <label>Tel</label>
                <input type="text" className="form-control" value={tel} onChange={(e) => setTel(e.target.value)} placeholder="Tel" />
                <label>Password</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
                <label>Password(repeat)</label>
                <input type="password" className="form-control" value={password2} onChange={(e) => setPassword2(e.target.value)} placeholder="Password(repeat)" />
               <Button className="btn btn-primary" loading={loading} onClick={handleSubmit} style={{color:'white'}}>Enregistrement &nbsp;&nbsp;&nbsp; <span className="fa fa-save"></span></Button>
          </div>
        </div>
        <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
      </div>
      <br /><br /><br />
    </div>
        </>
    );
}