import { Button, notification } from "antd";
import axios from "axios";
import { useState } from "react";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { mainViewState } from "../../../../atoms/AppAtome";
import { useRecoilState } from "recoil";
import { CheckCircleOutlined } from "@ant-design/icons";

export default function Trans2(){

  const [mainView, setMainView] = useRecoilState(mainViewState);

    const [nbSallesClasse, setNbSallesClass] = useState('');
    const [dureSallesClasse, setDureSallesClasse] = useState('');
    const [semidurSallesClasse, setSemidureSallesClasse] = useState('');
    const [matProvSallesClasse, setMatProvSallesClasse] = useState('');

    const [nbSallesEnseignants, setNbSallesEnseignants] = useState('');
    const [dureSallesEnseignant, setDureSallesEnseignant] = useState('');
    const [semidurSallesEnseignant, setSemidurSallesEnseignant] = useState('');
    const [matProvSallesEnseignant, setMatProvSallesEnseignant] = useState('');

    const [nbBibliotheque, setNbBibliotheque] = useState('');
    const [dureBibliotheque, setDureBibliotheque] = useState('');
    const [semiDurBibliotheque, setSemidurBibliotheque] = useState('');
    const [matProvBibliotheque, setMatProvBibliotheque] = useState('');

    const [nbSalleInformatique, setNbSalleInformatique] = useState('');
    const [dureSalleInformatique, setDureSalleInformatique] = useState('');
    const [semidurSalleInformatique, setSemidurSalleInformatique] = useState('');
    const [matProvSalleInformatique, setMatProvSalleInformatique] = useState('');

    const [nbInfirmerie, setNbInfirmerie] = useState('');
    const [dureInfirmerie, setDureInfirmerie] = useState('');
    const [semidurInfirmerie, setSemiDurInfirmerie] = useState('');
    const [matProvInfirmerie, setMatProvInfirmerie] = useState('');

    const [nbToilettesFille, setNbToilettesFille] = useState('');
    const [dureToilettesFille, setDureToilettesFille] = useState('');
    const [semidurToilettesFille, setSemiDurToilettesFille] = useState('');
    const [matProvToilettesFille, setMatProvToilettesFille] = useState('');

    const [nbToilettesGarcon, setNbToilettesGarcon] = useState('');
    const [dureToilettesGarcon, setDureToilettesGarcon] = useState('');
    const [semidurToilettesGarcon, setSemiDurToilettesGarcon] = useState('');
    const [matProvToilettesGarcon, setMatProvToilettesGarcon] = useState('');

    const [nbToilettesBarriere, setNbToilettesBarriere] = useState('');
    const [dureToilettesBarriere, setDureToilettesBarriere] = useState('');
    const [semidurToilettesBarriere, setSemiDurToilettesBarriere] = useState('');
    const [matProvToilettesBarriere, setMatProvToilettesBarriere ] = useState('');
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

      const {saveData,readData} = useLocalStorage();
      const datas = readData();

    const [loading, setLoading] = useState(false);

    const handleSumit = () => {
      setLoading(true);
      axios.post(APP_URL + 'esb/save-trans2',{
        'nbSallesClasse':nbSallesClasse,
        'dureSallesClasse':dureSallesClasse,
        'semidurSallesClasse':semidurSallesClasse,
        'matProvSallesClasse':matProvSallesClasse,
        'nbSallesEnseignants':nbSallesEnseignants,
        'dureSallesEnseignant':dureSallesEnseignant,
        'semidurSallesEnseignant':semidurSallesEnseignant,
        'matProvSallesEnseignant':matProvSallesEnseignant,
        'nbBibliotheque':nbBibliotheque,
        'dureBibliotheque':dureBibliotheque,
        'semiDurBibliotheque':semiDurBibliotheque,
        'matProvBibliotheque':matProvBibliotheque,
        'nbSalleInformatique':nbSalleInformatique,
        'dureSalleInformatique':dureSalleInformatique,
        'semidurSalleInformatique':semidurSalleInformatique,
        'matProvSalleInformatique':matProvSalleInformatique,
        'nbInfirmerie':nbInfirmerie,
        'dureInfirmerie':dureInfirmerie,
        'semidurInfirmerie':semidurInfirmerie,
        'matProvInfirmerie':matProvInfirmerie,
        'nbToilettesFille':nbToilettesFille,
        'dureToilettesFille':dureToilettesFille,
        'semidurToilettesFille':semidurToilettesFille,
        'matProvToilettesFille':matProvToilettesFille,
        'nbToilettesGarcon':nbToilettesGarcon,
        'dureToilettesGarcon':dureToilettesGarcon,
        'semidurToilettesGarcon':semidurToilettesGarcon,
        'matProvToilettesGarcon':matProvToilettesGarcon,
        'matProvToilettesGarcon':matProvToilettesGarcon,
        'nbToilettesBarriere':nbToilettesBarriere,
        'dureToilettesBarriere':dureToilettesBarriere,
        'semidurToilettesBarriere':semidurToilettesBarriere,
        'matProvToilettesBarriere':matProvToilettesBarriere,
        'rentreer_esb':datas.rentreer_esb,
        'infrastructure_id':datas.relationId
      },config).then((response) => {

        notification.open({
          message:(
             <div style={{ color: 'white' }}>
                Enregistrer avec success
             </div>
            ),
          description: '',
          placement:'bottomLeft', 
          icon: <CheckCircleOutlined />,
          style: {
              backgroundColor: 'green', // Couleur de fond personnalisée
              color:'white',
              // Autres styles personnalisés...
            },
        })

        let localData  = readData(); // on recupere les donnees de la memoire local
        localData.keyView = 'administration'; // on enregistre la vue en cours
        localData.relationId = null;

        saveData(JSON.parse(JSON.stringify(localData)));

        setMainView('administration');

      }).catch((error) => {
        console.log(error);
        setLoading(false);
      })
    }
    


    return (
        <>
         <div class="container-fluid">

          <div class="card mb-3">
          <div class="card-body">
                <h2> Types de salles </h2>
          </div>
          </div>

          <div class="card mb-3">
          <div class="card-body">

            <h4>Salles de classe </h4>

                <h6> Nombre de salles de classe </h6>
                <input type="text" value={nbSallesClasse} onChange={(e) => setNbSallesClass(e.target.value)} className="form-control" />

                <h6> Nombre de salles de classe en dur</h6>
                <input type="text" value={dureSallesClasse} onChange={(e) => setDureSallesClasse(e.target.value)} className="form-control" />
                
                <h6> Nombre de salles de classe en semi-dur</h6>
                <input type="text" value={semidurSallesClasse} onChange={(e) => setSemidureSallesClasse(e.target.value)} className="form-control" />

                <h6> Nombre de salles de classe en matériaux provisoire </h6>
                <input type="text" value={matProvSallesClasse} onChange={(e) => setMatProvSallesClasse(e.target.value)} className="form-control" />
            
            <h4>Salle des enseignants </h4>
            <h6> Nombre de salles enseignants </h6>
                <input type="text" value={nbSallesEnseignants} onChange={(e) => setNbSallesEnseignants(e.target.value)} className="form-control" />

                <h6> Nombre de salles enseignants en dur</h6>
                <input type="text" value={dureSallesEnseignant} onChange={(e) => setDureSallesEnseignant(e.target.value)} className="form-control" />
                
                <h6> Nombre de salles enseignants en semi-dur</h6>
                <input type="text" value={semidurSallesEnseignant} onChange={(e) => setSemidurSallesEnseignant(e.target.value)} className="form-control" />

                <h6> Nombre de salles enseignants en matériaux provisoire </h6>
                <input type="text" value={matProvSallesEnseignant} onChange={(e) => setMatProvSallesEnseignant(e.target.value)} className="form-control" />
            
            <h4> Bibliotheque </h4>
            <h6> Nombre de Bibliotheque </h6>
                <input type="text" value={nbBibliotheque} onChange={(e) => setNbBibliotheque(e.target.value)} className="form-control" />

                <h6> Nombre Bibliotheque en dur</h6>
                <input type="text" value={dureBibliotheque} onChange={(e) => setDureBibliotheque(e.target.value)} className="form-control" />
                
                <h6> Nombre de Bibliotheque en semi-dur</h6>
                <input type="text" value={semiDurBibliotheque} onChange={(e) => setSemidurBibliotheque(e.target.value)} className="form-control" />

                <h6> Nombre de Bibliotheque en matériaux provisoire </h6>
                <input type="text" value={matProvBibliotheque} onChange={(e) => setMatProvBibliotheque(e.target.value)} className="form-control" />
                
                
            <h4> Salle Informatique </h4>

            <h6> Nombre de Salle Informatique </h6>
                <input type="text" value={nbSalleInformatique} onChange={(e) => setNbSalleInformatique(e.target.value)} className="form-control" />

                <h6> Nombre de Salle Informatique en dur</h6>
                <input type="text" value={dureSalleInformatique} onChange={(e) => setDureSalleInformatique(e.target.value)} className="form-control" />
                
                <h6> Nombre de Salle Informatique en semi dur</h6>
                <input type="text" value={semidurSalleInformatique} onChange={(e) => setSemidurSalleInformatique(e.target.value)} className="form-control" />

                <h6> Nombre de Salle Informatique en Materiaux provisoire </h6>
                <input type="text" value={matProvSalleInformatique} onChange={(e) => setMatProvSalleInformatique(e.target.value)} className="form-control" />
            
    <h4> Infirmerie</h4>

  <h6> nombre Infirmerie </h6>
    <input type="text" value={nbInfirmerie} onChange={(e) => setNbInfirmerie(e.target.value)} className="form-control" />

    <h6> Nombre d'Infirmerie en dur</h6>
    <input type="text" value={dureInfirmerie} onChange={(e) => setDureInfirmerie(e.target.value)} className="form-control" />
    
    <h6> Nombre de Infirmerie en semi dur</h6>
    <input type="text" value={semidurInfirmerie} onChange={(e) => setSemiDurInfirmerie(e.target.value)} className="form-control" />

    <h6> Nombre de Infirmerie en Materiaux provisoire </h6>
    <input type="text" value={matProvInfirmerie} onChange={(e) => setMatProvInfirmerie(e.target.value)} className="form-control" />

    <h4> Toilettes filles </h4>

    <h6> nombre de Toilettes filles </h6>
    <input type="text" value={nbToilettesFille} onChange={(e) => setNbToilettesFille(e.target.value)} className="form-control" />

    <h6> Nombre de Toilettes filles en dur</h6>
    <input type="text" value={dureToilettesFille} onChange={(e) => setDureToilettesFille(e.target.value)} className="form-control" />
    
    <h6> Nombre de Toilettes filles en semi dur</h6>
    <input type="text" value={semidurToilettesFille} onChange={(e) => setSemiDurToilettesFille(e.target.value)} className="form-control" />

    <h6> Nombre de Toilettes filles en Materiaux provisoire </h6>
    <input type="text" value={matProvToilettesFille} onChange={(e) => setMatProvToilettesFille(e.target.value)} className="form-control" />

    <h4> Toilettes Garçon </h4>

<h6> nombre de Toilettes Garçon </h6>
<input type="text" value={nbToilettesGarcon} onChange={(e) => setNbToilettesGarcon(e.target.value)} className="form-control" />

<h6> Nombre de Toilettes Garçon en dur</h6>
<input type="text" value={dureToilettesGarcon} onChange={(e) => setDureToilettesGarcon(e.target.value)} className="form-control" />

<h6> Nombre de Toilettes Garçon en semi dur</h6>
<input type="text" value={semidurToilettesGarcon} onChange={(e) => setSemiDurToilettesGarcon(e.target.value)} className="form-control" />

<h6> Nombre de Toilettes Garçon en Materiaux provisoire </h6>
<input type="text" value={matProvToilettesGarcon} onChange={(e) => setMatProvToilettesGarcon(e.target.value)} className="form-control" />

<h4> Barrieres </h4>

<h6> nombre de Barrieres </h6>
<input type="text" value={nbToilettesBarriere} onChange={(e) => setNbToilettesBarriere(e.target.value)} className="form-control" />

<h6> Nombre de Barrieres en dur</h6>
<input type="text" value={dureToilettesBarriere} onChange={(e) => setDureToilettesBarriere(e.target.value)} className="form-control" />

<h6> Nombre de Barrieres en semi dur</h6>
<input type="text" value={semidurToilettesBarriere} onChange={(e) => setSemiDurToilettesBarriere(e.target.value)} className="form-control" />

<h6> Nombre de Barrieres en Materiaux provisoire </h6>
<input type="text" value={matProvToilettesBarriere} onChange={(e) => setMatProvToilettesBarriere(e.target.value)} className="form-control" />
<br />
<Button className="btn btn-primary" loading={loading} onClick={handleSumit}>Suivant </Button>
          </div>
          </div>

          </div>

          <br /><br /><br />
        </>
    );
}