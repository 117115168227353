import { useRecoilState } from "recoil";
import { mainViewState, rapportIdState, relationIdState, rentreeESBIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { Spin } from "antd";


export default function MyEnseigmentBase(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [rapportId, setRapportId] = useRecoilState(rapportIdState);
    const [rentreeEsbId, setRentreeEsbId] = useRecoilState(rentreeESBIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(true);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
    const config = {
        headers: headers, 
        };

    const [rapports, setRapports] = useState([]);

    useEffect(() => {

        axios.get(APP_URL + 'user/mes-rapports-ensg-base',config).then((response) => {
            setLoading(false);
            setRapports(response.data)
            setRapportId(response.data.id);
      
        }).catch((error) => {
            console.log(error);
        })

    },[]);

    return(
        <div class="container-fluid">

        <div class="card mb-3">
            <div class="card-body">
                <h2> Choisie un rapport</h2>
            </div>
        </div>
        
         <div class="card mb-3">
            <div class="card-body">
                {loading? <>
                
                    <center><Spin size="large" /></center>
                    
                </>:<>
 <div class="grid-container">

    
    {rapports.map((item) => (
        <>

    <a href="javascript:void(0)">
    <div class="grid-item"> 
      <p><b><span className="fa fa-file"></span> {item.denomination}</b></p>
      <button onClick={() =>{
             // recuperons maintenant l'id de la rentree de l'enseignement de base
            axios.get(APP_URL + 'user/rentree-esb/'+item.id,config).then((response) => {
                    setRentreeEsbId(response.data.rentree_esb_id);
                    // console.log('ici les donnee de rentree sesb: ',response.data)
            }).catch((error) => {
                    console.log(error);
            })
            setMainView('detail_update_rapport')
    
        }} className="btn btn-primary">Modifier</button>
    </div>
    </a>

        </>
    ))}
  
    
  </div>

                </>}
            </div>
            <br /><br /><br />
         </div>
         <style jsx>{`
         
         .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Crée des colonnes dynamiques */
            gap: 20px; /* Espace entre les éléments de la grille */
          }
          
          .grid-item {
            border: 1px solid #ccc; /* Bordure autour des éléments */
            padding: 10px; /* Espacement à l'intérieur des éléments */
            text-align: center; /* Alignement du texte au centre */
          }
          
          .grid-item img {
            max-width: 100%; /* Pour s'assurer que les images ne dépassent pas de leur conteneur */
            height: auto; /* Pour préserver les proportions de l'image */
          }
          
         
         `}</style>
       </div>
    );
}