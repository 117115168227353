import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function EtatNominatif(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const [nomPrenom, setNomPrenom] = useState('');
        const [diplomeEleve, setDiplomeEleve] = useState('');
        const [categorie, setCategorie] = useState('');
        const [classEnseigne, setClassEnseigne] = useState('');
        const [numAutEnseigner, setNumAutEnseigner] = useState('');
        const [salaireButeAnnuel, setSalaireBrutAnnuel] = useState('');
        const [indemniteAnnuel, setIndemniteAnnuel] = useState('');
        const [cumulSalairePercusDate, setCumulSalairePercusDate] = useState('');
        const [retenuesAnnuel, setRetenuesAnnuelles] = useState('');
        const [montantSalaire, setMontantSalaire] = useState('');
        const [emargement, setEmargement] = useState('');

        const handleNext = () => {
            setLoading(true);

            axios.post(APP_URL+'esb/save-nominatif/'+datas.rentreer_esb,{
                'nomPrenom':nomPrenom,
                'diplomeEleve':diplomeEleve,
                'categorie':categorie,
                'classEnseigne':classEnseigne,
                'numAutEnseigner':numAutEnseigner,
                'salaireButeAnnuel':salaireButeAnnuel,
                'indemniteAnnuel':indemniteAnnuel,
                'cumulSalairePercusDate':cumulSalairePercusDate,
                'retenuesAnnuel':retenuesAnnuel,
                'montantSalaire':montantSalaire,
                'emargement':emargement
            },config).then((response) => {

                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'endd';
                localData.isOk = true;
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                setAspectFinancierid(response.data.datas.aspectFinancierId);
                
                 // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })
    
              setMainView('endd');
    
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            })
        }


    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Etat nominatif du personnels </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    <h6>Nom et prenom </h6>
        <input type="text" className="form-control" value={nomPrenom} onChange={(e) => setNomPrenom(e.target.value)} />
    <br />
    <h6>Diplome le plus eleve </h6>
        <input type="text" className="form-control" value={diplomeEleve} onChange={(e) => setDiplomeEleve(e.target.value)} />
    <br />

    <h6> Categorie </h6>
        <input type="text" className="form-control" value={categorie} onChange={(e) => setCategorie(e.target.value)} />
    <br />

    <h6>Class Enseigne </h6>
        <input type="text" className="form-control" value={classEnseigne} onChange={(e) => setClassEnseigne(e.target.value)} />
    <br />

    <h6>Numero aut. d'enseignement </h6>
        <input type="text" className="form-control" value={numAutEnseigner} onChange={(e) => setNumAutEnseigner(e.target.value)} />
    <br />

    <h6>Salaires brut annuels </h6>
        <input type="text" className="form-control" value={salaireButeAnnuel} onChange={(e) => setSalaireBrutAnnuel(e.target.value)} />
    <br />

    <h6>Indemnite Annuelle </h6>
        <input type="text" className="form-control" value={indemniteAnnuel} onChange={(e) => setIndemniteAnnuel(e.target.value)} />
    <br />

    <h6>Cumul Salaires Percus en date </h6>
        <input type="text" className="form-control" value={cumulSalairePercusDate} onChange={(e) => setCumulSalairePercusDate(e.target.value)} />
    <br />

    <h6>Retenues annuelles </h6>
        <input type="text" className="form-control" value={retenuesAnnuel} onChange={(e) => setRetenuesAnnuelles(e.target.value)} />
    <br />

    <h6>Montant salaires dus </h6>
        <input type="text" className="form-control" value={montantSalaire} onChange={(e) => setMontantSalaire(e.target.value)} />
    <br />

    <h6>Emargement </h6>
        <input type="text" className="form-control" value={emargement} onChange={(e) => setEmargement(e.target.value)} />
    <br />

    <br />
    <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>

    </div>
 </div>
</div>
        </>
    );
}