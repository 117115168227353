import React from 'react';
// import './Carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import img1 from '../assets/digitalisation-1170x385.png'
import img2 from '../assets/definition-digitalisation-5f1bd72b2402c.png'
import img3 from '../assets/megatrends-digitalisation-1200x739.jpg'


const Carousel = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000, // Changement de slide toutes les 3 secondes
      };

  return (
    <div>
   
     <Slider {...settings} className="carousel-container">
      <div className="slide" style={{ background: `url(${img2}) center/cover` }}>
        <div className="slide-content">
            <img src={`${img2}`} />
          <h2>Gerez plus aisement vos documents</h2>
        </div>
      </div>
      <div className="slide" style={{ background: `url(${img1}) center/cover` }}>
        <div className="slide-content">
        <img src={`${img1}`} />
          <h2>Où que vous soyez</h2>
        </div>
      </div>
      <div className="slide" style={{ background: `url(${img3}) center/cover` }}>
        <div className="slide-content">
        <img src={`${img3}`} />
          <h2>Texte 3</h2>
        </div>
      </div>
    </Slider>

      <style jsx>{`
       /* Stylez votre carrousel ici */

       .carousel-container {
         width: 100%;
       }
       
       .slide {
         position: relative;
         height: 400px; /* Ajustez la hauteur selon vos besoins */
         color: #fff;
       }
       
       .slide-content {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         text-align: center;
       }
       
       .slide h2 {
         font-size: 24px;
       }
       
       /* Ajoutez des styles supplémentaires au besoin */
       
      `}</style>
    </div>
    
  );
}

export default Carousel;
