import { Button, notification } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function Aspect_financier(){

    const [frais, setFrais] = useState(null);
    const [designations, setDesignations] = useState('');

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };


    const handleNext = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-aspect-financiers/'+datas.rentreer_esb,{

            'frais':frais,
            'designations':designations

        },config).then((response) => {

            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'contribution_exigible_verse';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            setAspectFinancierid(response.data.datas.aspectFinancierId);
            
              // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('contribution_exigible_verse');

        }).catch((error) => { console.log(error); setLoading(false) });
    }

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Aspect financiers </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">
        
  
        <h6>Percevez vous d'autre frais en dehors de l'inscription et les frais de scolarite? </h6>
        {/* <input type="text" className="form-control" value={quartier} onChange={(e) => setQuartier(e.target.value)} /> */}
        <select className="form-control" onChange={(e) => setFrais(e.target.value)} >
            <option value={true} onClick={() =>  setFrais(true)}>OUI</option>
            <option value={0} onClick={() =>  setFrais(false)}>NON</option>
        </select>

        <br /><br />
        <h6> Si oui precisez leurs designations et montants </h6>
        <textarea value={designations} onChange={(e) => setDesignations(e.target.value)} className="form-control"></textarea>
    
        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>

    </div>
 </div>
</div>
        </>
    );
}
