import Link from "antd/es/typography/Link";
import Carousel from "./Carousel";
import Navbar from "./Navbar";


export default function Home(){

    return (
        <>
   
        <Navbar />
        <Carousel />
<center>
    <br /><br />
        <a href={'/lookup'} style={{color:'white'}} className="btn btn-primary btn-lg" >Connexion <span className="fa fa-user"></span></a>
</center>
<br /><br /> <br /><br />
        </>
    );
}