import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function Synthese_effectifs(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const [maternelGacon, setMaternelGarcon] = useState('');
        const [maternelFille, setMaternelFille] = useState('');
        
        const [silGarcon, setSilGarcon] = useState('');
        const [silFille, setSilFille] = useState('');

        const [cpGarcon, setCpGarcon] = useState('');
        const [cpFille, setCpFille] = useState('');

        const [ce1Garcon, setCe1Garcon] = useState('');
        const [ce1Fille, setCe1Fille] = useState('');

        const [ce2Garcon, setCe2Garcon] = useState('');
        const [ce2Fille, setCe2Fille] = useState('');
        
        const [cm1Garcon, setCm1Garcon] = useState('');
        const [cm1Fille, setCm1Fille] = useState('');

        const [cm2Garcon, setCm2Garcon] = useState('');
        const [cm2Fille, setCm2Fille] = useState('')

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[]);

    const handleNext = () => {
        
        setLoading(true);
        axios.post(APP_URL+'esb/save-synthese-effectif',{
            'rentreer_esb' : datas.rentreer_esb,
            'maternelGacon' : maternelGacon,
            'maternelFille':maternelFille,
            'silGarcon':silGarcon,
            'silFille':silFille,
            'cpGarcon':cpGarcon,
            'cpFille':cpFille,
            'ce1Garcon':ce1Garcon,
            'ce1Fille':ce1Fille,
            'ce2Garcon':ce2Garcon,
            'ce2Fille':ce2Fille,
            'cm1Garcon':cm1Garcon,
            'cm1Fille':cm1Fille,
            'cm2Garcon':cm2Garcon,
            'cm2Fille':cm2Fille,
        },config).then((response) => {

            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'etablissement_environnants';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('etablissement_environnants');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Synthese des effectifs</h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>Mat</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={maternelGacon} onChange={(e) => setMaternelGarcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={maternelFille} onChange={(e) => setMaternelFille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>Sil</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={silGarcon} onChange={(e) => setSilGarcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={silFille} onChange={(e) => setSilFille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>CP</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={cpGarcon} onChange={(e) => setCpGarcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={cpFille} onChange={(e) => setCpFille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>CE1</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={ce1Garcon} onChange={(e) => setCe1Garcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={ce1Fille} onChange={(e) => setCe1Fille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>CE2</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={ce2Garcon} onChange={(e) => setCe2Garcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={ce2Fille} onChange={(e) => setCe2Fille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>CM1</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={cm1Garcon} onChange={(e) => setCm1Garcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={cm1Fille} onChange={(e) => setCm1Fille(e.target.value)} />

    </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>CM2</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={cm2Garcon} onChange={(e) => setCm2Garcon(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={cm2Fille} onChange={(e) => setCm2Fille(e.target.value)} />
        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>
    </div>
 </div>
        <br /><br />
 </div>

        </>
    );
}