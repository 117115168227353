import { Link } from "react-router-dom";
import { mainViewState } from "../../atoms/AppAtome";
import { useRecoilState } from "recoil";
import { Button, notification } from "antd";
import { useState } from "react";
import { CheckOutlined,WarningOutlined } from "@ant-design/icons";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";


export default function CreateMySchool(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [denomination, setDenomination] = useState('');
    const [type, setType] = useState('bilingue');
    const [langue, setLangue] = useState('');
    const [localite, setLocalite] = useState('');
    const [telFondateur, setTelFondateur] = useState('');
    const [telEcole, setTelEcole] = useState('');
    const [telPrincipal, setTelPrincipal] = useState('');
    const [numeroAutorisation, setNumeroAutorisation] = useState('');
    const [nomPrincipal, setNomPrincipal] = useState('');
    const [dateOuverture, setDateOuverture] = useState('');
    const [nomFondateur, setNomFondateur] = useState('');
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [categorie, setCategorie] = useState('');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

    const handleSubmit = () =>{
       if(denomination != '' || nomPrincipal !='' || type != '' || langue != '' || localite != '' || telFondateur != '' || telEcole != '' || telPrincipal != '' || numeroAutorisation != '' || dateOuverture != '' || nomFondateur != ''){
          setLoading(true);
          axios.post(APP_URL+'user/save-school',{

            'denomination' : denomination,
            'type':type,
            'langue' : langue,
            'localite': telFondateur,
            'telEcole' : telEcole,
            'telPrincipal':telPrincipal,
            'numeroAutorisation' : numeroAutorisation,
            'dateOuverture' : dateOuverture,
            'nomFondateur' : nomFondateur,
            'nomPrincipal':nomPrincipal,
            'telFondateur':telFondateur,
            'categorie':categorie,

          },config).then((response) => {
              console.log(response.data);

              setLoading(false);

              notification.open({
                message:(
                    <div style={{ color: 'white' }}>
                      {response.data.msg}
                    </div>
                  ),
                description: '',
                placement:'bottomLeft',
                icon: <WarningOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white'
                    // Autres styles personnalisés...
                  },
              })

              setMainView('addSchool');

          }).catch((error) => {

            console.log(error);
            setLoading(false);

            notification.open({
              message:(
                  <div style={{ color: 'white' }}>
                    {error.response.data.detail}
                  </div>
                ),
              description: '',
              placement:'bottomLeft',
              icon: <WarningOutlined />,
              style: {
                  backgroundColor: 'red', // Couleur de fond personnalisée
                  color:'white',
                  // Autres styles personnalisés...
                },
            })

          })

       }else{

        notification.open({
          message:(
              <div style={{ color: 'white' }}>
                Un ou plusieurs champs sont manquants
              </div>
            ),
          description: '',
          placement:'bottomLeft',
          icon: <WarningOutlined />,
          style: {
              backgroundColor: 'red', // Couleur de fond personnalisée
              color:'white'
              // Autres styles personnalisés...
            },
        })

       }
    }

    return (
<>
  <div class="container-fluid">
     
     <ol class="breadcrumb">
       <li class="breadcrumb-item">
         <a href="#">Dashboard</a>
       </li>
       <li class="breadcrumb-item active">formulaire</li>
     </ol>

     <div class="card-header">
          <i class="fa fa-table"></i> Creer une nouvelle ecole maintenant  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <Link to={'javascript:void(0)'} onClick={() => setMainView('addSchool')} className="btn btn-primary">Infos de votre ecole <span className="fa fa-list"></span></Link>
     </div>

     <div class="card-body">
        <div class="table-responsive">
            
            <input type="text" className="form-control" value={denomination} onChange={(e) => setDenomination(e.target.value)} placeholder="denomination" />
            <label>Type</label>
            <select className="form-control"  value={type} onChange={(e) => setType(e.target.value)}>
                <option value={'bilingue'}>Bilingue</option>
                <option value={'non bilingue'}>Non bilingue</option>
            </select><br />
            <label>Categorie</label>
            <select className="form-control" value={categorie} onChange={(e) => setCategorie(e.target.value)}>
              <option value={'Enseignement général'}>Enseignement général</option>
              <option value={'Enseignement de base'}>Enseignement de base</option>
            </select><br />
            <input type="text" className="form-control" value={langue} onChange={(e) => setLangue(e.target.value)} placeholder="langue" /><br />
            <input type="text" className="form-control" placeholder="localite" value={localite} onChange={(e) => setLocalite(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="tel fondateur" value={telFondateur} onChange={(e) => setTelFondateur(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="tel ecole" value={telEcole} onChange={(e) => setTelEcole(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="tel principal" value={telPrincipal} onChange={(e) => setTelPrincipal(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="nom principal" value={nomPrincipal} onChange={(e) => setNomPrincipal(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="nom fondateur" value={nomFondateur} onChange={(e) => setNomFondateur(e.target.value)} /><br />
            <input type="text" className="form-control" placeholder="numero autorisation" value={numeroAutorisation} onChange={(e) => setNumeroAutorisation(e.target.value)} /><br />
            <label>Date d'ouverture</label>
            <input type="date" className="form-control" placeholder="date ouverture"  value={dateOuverture} onChange={(e) => setDateOuverture(e.target.value)} /><br />
            {/* <label>plan localisation</label>
            <input type="file" className="form-control" placeholder="langue" /><br /> */}

            <Button loading={loading} onClick={handleSubmit} className="btn btn-primary">Envoyer</Button>
        </div>
    </div>
      <br /><br /><br /><br />
  </div>
</>
    );
}