import { useRecoilState } from "recoil";
import { mainViewState, rapportIdState, relationIdState, rentreeESBIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { useCookies } from "react-cookie";

export default function DetailUpdateRapport() {

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(true);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [rapportId, setRapportId] = useRecoilState(rapportIdState);
    const [rentreeEsbId, setRentreeEsbId] = useRecoilState(rentreeESBIdState);
  
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
    const config = {
        headers: headers, 
        };


    return(
        <>
        
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Choisissez une carte a modifier </h2>
    </div>
</div>

 
    <div class="grid-container">

    <a href="javascript:void(0)">
    <div class="grid-item"> 
      <p><b><span className="fa fa-file"></span> <h3> Infrastructure </h3></b></p>
      <button className="btn btn-primary" onClick={() => setMainView('update_infrastructure') }>Modifier</button>
    </div>
    </a>

   
    <a href="javascript:void(0)">
    <div class="grid-item"> 
      <p><b><span className="fa fa-file"></span> <h3> Enseignement </h3> </b></p>
      <button className="btn btn-primary">Modifier</button>
    </div>
    </a>

        
    <a href="javascript:void(0)">
    <div class="grid-item"> 
      <p><b><span className="fa fa-file"></span> <h3> Administration </h3> </b></p>
      <button className="btn btn-primary">Modifier</button>
    </div>
    </a>


    <a href="javascript:void(0)">
    <div class="grid-item"> 
      <p><b><span className="fa fa-file"></span> <h4> Deroulement rentree </h4> </b></p>
      <button className="btn btn-primary">Modifier</button>
    </div>
    </a>

   
  </div>

</div>

<style jsx>{`
         
         .grid-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Crée des colonnes dynamiques */
            gap: 20px; /* Espace entre les éléments de la grille */
          }
          
          .grid-item {
            border: 1px solid #ccc; /* Bordure autour des éléments */
            padding: 10px; /* Espacement à l'intérieur des éléments */
            text-align: center; /* Alignement du texte au centre */
          }
          
          .grid-item img {
            max-width: 100%; /* Pour s'assurer que les images ne dépassent pas de leur conteneur */
            height: auto; /* Pour préserver les proportions de l'image */
          }
          
         
         `}</style>
        
        </>
    )
}