import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function Administration(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    // const [denomination, setDenomination] = useState('');
    const [nomPrenomDirecteur, setNomPrenomDirecteur] = useState('');
    const [telDirecteur, setTelDirecteur] = useState('');
    const [qualificationDirecteur, setQualificationDirecteur] = useState('');
    const [datePriseFonctionDirecteur, setDatePriseFonctionDirecteur] = useState('');
    const [numeroAgrementDirecteur, setNumeroAgrementDirecteur] = useState('');

    const [nomPrenomDirecteurAdjoin, setNomPrenomDirecteurAdjoin] = useState('');
    const [telDirecteurAdjoin, setTelDirecteurAdjoin] = useState('');
    const [qualificationDirecteurAdjoin, setQualificationDirecteurAdjoin] = useState('');
    const [datePriseFonctionDirecteurAdjoin, setDatePriseFonctionDirecteurAdjoin] = useState('');
    const [numeroAgrementDirecteurAdjoin, setNumeroAgrementDirecteurAdjoin] = useState('');

    const [nomPrenomSecretaire, setNomPrenomSecretaire] = useState('');
    const [telSecretaire, setTelSecretaire] = useState('');
    const [qualificationSecretaire, setQualificationSecretaire] = useState('');
    const [datePriseFonctionSecretaire, setDatePriseFonctionSecretaire] = useState('');
    const [numeroAgrementSecretaire, setNumeroAgrementSecretaire] = useState('');

    const [nomPrenomAgentFinancier, setNomPrenomAgentFinancier] = useState('');
    const [telAgentFinancier, setTelAgentFinancier] = useState('');
    const [qualificationAgentFinancier, setQualificationAgentFinancier] = useState('');
    const [datePriseFonctionAgentFinancier, setDatePriseFonctionAgentFinancier] = useState('');
    const [numeroAgrementAgentFinancier, setNumeroAgrementAgentFinancier] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    },[]);

    const handleSubmit = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-administration',{
            'nomPrenomDirecteur':nomPrenomDirecteur,
            'telDirecteur':telDirecteur,
            'qualificationDirecteur':qualificationDirecteur,
            'datePriseFonctionDirecteur':datePriseFonctionDirecteur,
            'numeroAgrementDirecteur':numeroAgrementDirecteur,

            'nomPrenomDirecteurAdjoin':nomPrenomDirecteurAdjoin,
            'telDirecteurAdjoin':telDirecteurAdjoin,
            'qualificationDirecteurAdjoin':qualificationDirecteurAdjoin,
            'datePriseFonctionDirecteurAdjoin':datePriseFonctionDirecteurAdjoin,
            'numeroAgrementDirecteurAdjoin':numeroAgrementDirecteurAdjoin,
            
            'nomPrenomSecretaire':nomPrenomSecretaire,
            'telSecretaire':telSecretaire,
            'qualificationSecretaire':qualificationSecretaire,
            'datePriseFonctionSecretaire':datePriseFonctionSecretaire,
            'numeroAgrementSecretaire':numeroAgrementSecretaire,

            'nomPrenomAgentFinancier':nomPrenomAgentFinancier,
            'telAgentFinancier':telAgentFinancier,
            'qualificationAgentFinancier':qualificationAgentFinancier,
            'datePriseFonctionAgentFinancier':datePriseFonctionAgentFinancier,
            'numeroAgrementAgentFinancier':numeroAgrementAgentFinancier,
            'rentreer_esb' : datas.rentreer_esb,

        },config).then((response) => {
            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.infrastructure_id; // on enregistre la vue en cours
            localData.keyView = 'deroulement_rentree';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

              // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('deroulement_rentree');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return (
        <>
         <div class="container-fluid">

                <div class="card mb-3">
                    <div class="card-body">
                        <h2> Administration </h2>
                    </div>
                </div>

            <div class="card mb-3">
                <div class="card-body">
                {/* <h6> Denomination </h6>
                    <input type="text" value={denomination} onChange={(e) => setDenomination(e.target.value)} className="form-control" />
                 */}
                 <h2>Directeur</h2>
                <h6> nom prenom du directeur </h6>
                    <input type="text" value={nomPrenomDirecteur} onChange={(e) => setNomPrenomDirecteur(e.target.value)} className="form-control" />
                <h6> numero de tel du directeur </h6>
                    <input type="text" value={telDirecteur} onChange={(e) => setTelDirecteur(e.target.value)} className="form-control" />
                <h6> Qualification du directeur </h6>
                    <input type="text" value={qualificationDirecteur} onChange={(e) => setQualificationDirecteur(e.target.value)} className="form-control" />
                <h6> Date de prise de fonction du directeur </h6>
                    <input type="date" value={datePriseFonctionDirecteur} onChange={(e) => setDatePriseFonctionDirecteur(e.target.value)} className="form-control" />
                <h6> Numero d'agrement du directeur </h6>
                    <input type="text" value={numeroAgrementDirecteur} onChange={(e) => setNumeroAgrementDirecteur(e.target.value)} className="form-control" />
                
                 <h2>Directeur Adjoin</h2>
                    <h6> nom prenom du directeur adjoin </h6>
                    <input type="text" value={nomPrenomDirecteurAdjoin} onChange={(e) => setNomPrenomDirecteurAdjoin(e.target.value)} className="form-control" />
                <h6> numero de tel du directeur adjoin </h6>
                    <input type="text" value={telDirecteurAdjoin} onChange={(e) => setTelDirecteurAdjoin(e.target.value)} className="form-control" />
                <h6> Qualification du directeur adjoin </h6>
                    <input type="text" value={qualificationDirecteurAdjoin} onChange={(e) => setQualificationDirecteurAdjoin(e.target.value)} className="form-control" />
                <h6> Date de prise de fonction du directeur adjoin </h6>
                    <input type="date" value={datePriseFonctionDirecteurAdjoin} onChange={(e) => setDatePriseFonctionDirecteurAdjoin(e.target.value)} className="form-control" />
                <h6> Numero d'agrement du directeur adjoin </h6>
                    <input type="text" value={numeroAgrementDirecteurAdjoin} onChange={(e) => setNumeroAgrementDirecteurAdjoin(e.target.value)} className="form-control" />
                
                 <h2>secrétaire</h2>
                    <h6> nom prenom de la secrétaire </h6>
                    <input type="text" value={nomPrenomSecretaire} onChange={(e) => setNomPrenomSecretaire(e.target.value)} className="form-control" />
                <h6> numero de tel de la secrétaire </h6>
                    <input type="text" value={telSecretaire} onChange={(e) => setTelSecretaire(e.target.value)} className="form-control" />
                <h6> Qualification de la secrétaire </h6>
                    <input type="text" value={qualificationSecretaire} onChange={(e) => setQualificationSecretaire(e.target.value)} className="form-control" />
                <h6> Date de prise de la secrétaire </h6>
                    <input type="date" value={datePriseFonctionSecretaire} onChange={(e) => setDatePriseFonctionSecretaire(e.target.value)} className="form-control" />
                <h6> Numero d'agrement de la secrétaire </h6>
                    <input type="text" value={numeroAgrementSecretaire} onChange={(e) => setNumeroAgrementSecretaire(e.target.value)} className="form-control" />
                

                    <h2>Agent financier </h2>
                    <h6> nom prenom de l'agent financier  </h6>
                    <input type="text" value={nomPrenomAgentFinancier} onChange={(e) => setNomPrenomAgentFinancier(e.target.value)} className="form-control" />
                <h6> numero de tel de l'agent financier </h6>
                    <input type="text" value={telAgentFinancier} onChange={(e) => setTelAgentFinancier(e.target.value)} className="form-control" />
                <h6> Qualification de l'agent financier </h6>
                    <input type="text" value={qualificationAgentFinancier} onChange={(e) => setQualificationAgentFinancier(e.target.value)} className="form-control" />
                <h6> Date de prise de l'agent financier </h6>
                    <input type="date" value={datePriseFonctionAgentFinancier} onChange={(e) => setDatePriseFonctionAgentFinancier(e.target.value)} className="form-control" />
                <h6> Numero d'agrement de l'agent financier </h6>
                    <input type="text" value={numeroAgrementAgentFinancier} onChange={(e) => setNumeroAgrementAgentFinancier(e.target.value)} className="form-control" />
                <br />
                <Button className="btn btn-primary" loading={loading} onClick={handleSubmit}>Suivant</Button>
                </div>
            </div>
        </div>
           <br /><br />
        </>
    );
}