

export default function Navbar(){

    return (
        <>
    <div className="navbar">
      <div className="logo">SEDUC</div>

      <div className="search">
        <input type="text" placeholder="Rechercher..."  className="form-control"/>
      </div>

      <div className="menu">
        {/* Ajoutez ici vos liens de navigation */}
        <a href="/">Accueil</a>
        <a href="/about">À propos</a>
        <a href="/contact">Contact</a>
      </div>
    </div>

    <style jsx>{`
        /* Stylez votre barre de navigation ici */

        .navbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          background-color: #333;
          color: #fff;
          width: 100%;
        }
        
        .logo {
          font-size: 24px;
          font-weight: bold;
        }
        
        .menu {
          display: flex;
          gap: 20px;
        }
        
        @media (max-width: 768px) {
          /* Ajoutez des styles pour rendre la barre de navigation responsive */
          .navbar {
            flex-direction: column;
            align-items: flex-start;
          }
        
          .menu {
            margin-top: 10px;
          }
        }

        /* Ajoutez des styles pour le champ de recherche */

.search {
  margin-right: 20px;
}

.search input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Ajoutez des styles supplémentaires au besoin */

        
    `}</style>
        </>
    );
}