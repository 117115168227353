import { Button, Result } from "antd";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../../atoms/AppAtome";


export default function Endd(){

    const [mainView, setMainView] = useRecoilState(mainViewState);

    return (
        <>
 <Result
    status="success"
    title="Bravo.. vous avez terminer avec vos enregistrements"
    subTitle="Vous pouvez routourner en acceuil mainant"
    extra={[
      <Button type="primary" key="console" onClick={() => setMainView('dashboad')}>
        Go HOME
      </Button>,
    //   <Button key="buy">Buy Again</Button>,
    ]}
  />
        </>
    );
}