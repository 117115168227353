import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { Button, notification } from "antd";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";


export default function Repartition_effectifs(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const [nbGarconRentreePetite, setNbGaconRentreePetite] = useState('');
    const [nbFilleRentreePetite, setNbFilleRentreePetite] = useState('');
    const [nbGarconRedoublantPetite, setNbGarconeRedoublantPetite] = useState('');
    const [nbFilleRedoublantePetite, setNbFilleRedoublantePetite] = useState('');

    const [nbGarconRentreeGrande, setNbGaconRentreeGrande] = useState('');
    const [nbFilleRentreeGrande, setNbFilleRentreeGrande] = useState('');
    const [nbGarconRedoublantGrande, setNbGarconeRedoublantGrande] = useState('');
    const [nbFilleRedoublanteGrande, setNbFilleRedoublanteGrande] = useState('');

    const [nbGarconRentreeSil, setNbGaconRentreeSil] = useState('');
    const [nbFilleRentreeSil, setNbFilleRentreeSil] = useState('');
    const [nbGarconRedoublantSil, setNbGarconeRedoublantSil] = useState('');
    const [nbFilleRedoublanteSil, setNbFilleRedoublanteSil] = useState('');

    const [nbGarconRentreeCp, setNbGaconRentreeCp] = useState('');
    const [nbFilleRentreeCp, setNbFilleRentreeCp] = useState('');
    const [nbGarconRedoublantCp, setNbGarconeRedoublantCp] = useState('');
    const [nbFilleRedoublanteCp, setNbFilleRedoublanteCp] = useState('');

    const [nbGarconRentreeCe1, setNbGaconRentreeCe1] = useState('');
    const [nbFilleRentreeCe1, setNbFilleRentreeCe1] = useState('');
    const [nbGarconRedoublantCe1, setNbGarconeRedoublantCe1] = useState('');
    const [nbFilleRedoublanteCe1, setNbFilleRedoublanteCe1] = useState('');

    const [nbGarconRentreeCe2, setNbGaconRentreeCe2] = useState('');
    const [nbFilleRentreeCe2, setNbFilleRentreeCe2] = useState('');
    const [nbGarconRedoublantCe2, setNbGarconeRedoublantCe2] = useState('');
    const [nbFilleRedoublanteCe2, setNbFilleRedoublanteCe2] = useState('');

    const [nbGarconRentreeCM1, setNbGaconRentreeCM1] = useState('');
    const [nbFilleRentreeCM1, setNbFilleRentreeCM1] = useState('');
    const [nbGarconRedoublantCM1, setNbGarconeRedoublantCM1] = useState('');
    const [nbFilleRedoublanteCM1, setNbFilleRedoublanteCM1] = useState('');

    const [nbGarconRentreeCM2, setNbGaconRentreeCM2] = useState('');
    const [nbFilleRentreeCM2, setNbFilleRentreeCM2] = useState('');
    const [nbGarconRedoublantCM2, setNbGarconeRedoublantCM2] = useState('');
    const [nbFilleRedoublanteCM2, setNbFilleRedoublanteCM2] = useState(''); 

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

        const handleNext = () => {

            setLoading(true);

            axios.post(APP_URL + 'esb/save-repartition',{
                'rentreer_esb' : datas.rentreer_esb,
            },config).then((response) => {
    
                setLoading(false);
                setRelationId(response.data.repartition_id);
                
                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_id; // on enregistre l'id de la repartition des effectifs
               
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
    
           if(

            nbGarconRentreePetite != '' &&
            nbFilleRentreePetite != '' &&
            nbGarconRedoublantPetite != '' &&
            nbFilleRedoublantePetite != '' &&
            nbGarconRentreeGrande != '' &&
            nbFilleRentreeGrande != '' &&
            nbGarconRedoublantGrande != '' &&
            nbFilleRedoublanteGrande != '' &&
            nbGarconRentreeSil != '' &&
            nbFilleRentreeSil != '' &&
            nbGarconRedoublantSil != '' &&
            nbFilleRedoublanteSil != '' &&
            nbGarconRentreeCp != '' &&
            nbFilleRentreeCp != '' &&
            nbGarconRedoublantCp != '' &&
            nbFilleRedoublanteCp != '' &&
            nbGarconRentreeCe1 != '' &&
            nbFilleRentreeCe1 != '' &&
            nbGarconRedoublantCe1 != '' &&
            nbFilleRedoublanteCe1 != '' &&
            nbGarconRentreeCe2 != '' &&
            nbFilleRentreeCe2 != '' &&
            nbGarconRedoublantCe2 != '' &&
            nbFilleRedoublanteCe2 != '' &&
            nbGarconRentreeCM1 != '' &&
            nbFilleRentreeCM1 != '' &&
            nbGarconRedoublantCM1 != '' &&
            nbFilleRedoublanteCM1 != '' &&
            nbGarconRentreeCM2 != '' &&
            nbFilleRentreeCM2 != '' &&
            nbGarconRedoublantCM2 != '' &&
            nbFilleRedoublanteCM2 != '' 

           ){

            axios.post(APP_URL+'esb/save-section',{
                'nbGarconRentreePetite':nbGarconRentreePetite,
                'nbFilleRentreePetite':nbFilleRentreePetite,
                'nbGarconRedoublantPetite':nbGarconRedoublantPetite,
                'nbFilleRedoublantePetite':nbFilleRedoublantePetite,

                'nbGarconRentreeGrande':nbGarconRentreeGrande,
                'nbFilleRentreeGrande':nbFilleRentreeGrande,
                'nbGarconRedoublantGrande':nbGarconRedoublantGrande,
                'nbFilleRedoublanteGrande':nbFilleRedoublanteGrande,

                'nbGarconRentreeSil':nbGarconRentreeSil,
                'nbFilleRentreeSil':nbFilleRentreeSil,
                'nbGarconRedoublantSil':nbGarconRedoublantSil,
                'nbFilleRedoublanteSil':nbFilleRedoublanteSil,

                'nbGarconRentreeCp':nbGarconRentreeCp,
                'nbFilleRentreeCp':nbFilleRentreeCp,
                'nbGarconRedoublantCp':nbGarconRedoublantCp,
                'nbFilleRedoublanteCp':nbFilleRedoublanteCp,

                'nbGarconRentreeCe1':nbGarconRentreeCe1,
                'nbFilleRentreeCe1':nbFilleRentreeCe1,
                'nbGarconRedoublantCe1':nbGarconRedoublantCe1,
                'nbFilleRedoublanteCe1':nbFilleRedoublanteCe1,

                'nbGarconRentreeCe2':nbGarconRentreeCe2,
                'nbFilleRentreeCe2':nbFilleRentreeCe2,
                'nbGarconRedoublantCe2':nbGarconRedoublantCe2,
                'nbFilleRedoublanteCe2':nbFilleRedoublanteCe2,

                'nbGarconRentreeCm1':nbGarconRentreeCM1,
                'nbFilleRentreeCm1':nbFilleRentreeCM1,
                'nbGarconRedoublantCm1':nbGarconRedoublantCM1,
                'nbFilleRedoublanteCm1':nbFilleRedoublanteCM1,

                'nbGarconRentreeCm2':nbGarconRentreeCM2,
                'nbFilleRentreeCm2':nbFilleRentreeCM2,
                'nbGarconRedoublantCm2':nbGarconRedoublantCM2,
                'nbFilleRedoublanteCm2':nbFilleRedoublanteCM2,

                'rentreer_esb' : datas.rentreer_esb,
                'repartition_effectif_id' : relationId,

            },config).then((response) => {

                setLoading(false);
                let localData  = readData(); // on recupere les donnees de la memoire local
                localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
                localData.keyView = 'synthese_effectifs';
                saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
    
                // on notifie 
              notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('synthese_effectifs');


            }).catch((error) => {

                console.log(error);
                setLoading(false);

            })

          }else{

            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     un ou plueieurs champs sont manquants dans le formulaire.
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <WarningOutlined />,
                style: {
                    backgroundColor: 'red', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

          }
        }    

    useEffect(() => {

        window.scrollTo({ top: 0, behavior: "smooth" });
       
    },[]);

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Repartition des effectifs {year}</h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

        <h4>Petite section</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreePetite}  onChange={ (e) => setNbGaconRentreePetite(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreePetite} onChange={ (e) => setNbFilleRentreePetite(e.target.value) } className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantPetite} onChange={(e) => setNbGarconeRedoublantPetite(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublantePetite} onChange={(e) => setNbFilleRedoublantePetite(e.target.value)} className="form-control" />
       
    </div>
   </div>

   <div class="card mb-3">
    <div class="card-body">
        
        <h4>Grande section</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeGrande} onChange={(e) => setNbGaconRentreeGrande(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeGrande} onChange={(e) => setNbFilleRentreeGrande(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantGrande} onChange={(e) => setNbGarconeRedoublantGrande(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteGrande} onChange={(e) => setNbFilleRedoublanteGrande(e.target.value)} className="form-control" />
       
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        
        <h4>SIL</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeSil} onChange={(e) => setNbGaconRentreeSil(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeSil} onChange={(e) => setNbFilleRentreeSil(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantSil} onChange={(e) => setNbGarconeRedoublantSil(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteSil} onChange={(e) => setNbFilleRedoublanteSil(e.target.value)} className="form-control" />
       
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        
        <h4>CP</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeCp} onChange={(e) => setNbGaconRentreeCp(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeCp} onChange={(e) => setNbFilleRentreeCp(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantCp} onChange={(e) => setNbGarconeRedoublantCp(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteCp} onChange={(e) => setNbFilleRedoublanteCp(e.target.value)} className="form-control" />
       
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        <h4>CE1</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeCe1} onChange={(e) => setNbGaconRentreeCe1(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeCe1} onChange={(e) => setNbFilleRentreeCe1(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantCe1} onChange={(e) => setNbGarconeRedoublantCe1(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteCe1} onChange={(e) => setNbFilleRedoublanteCe1(e.target.value)} className="form-control" />
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        <h4>CE2</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeCe2} onChange={(e) => setNbGaconRentreeCe2(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeCe2} onChange={(e) => setNbFilleRentreeCe2(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantCe2} onChange={(e) => setNbGarconeRedoublantCe2(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteCe2} onChange={(e) => setNbFilleRedoublanteCe2(e.target.value)} className="form-control" />
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        <h4>CM1</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeCM1} onChange={(e) => setNbGaconRentreeCM1(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeCM1} onChange={(e) => setNbFilleRentreeCM1(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantCM1} onChange={(e) => setNbGarconeRedoublantCM1(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteCM1} onChange={(e) => setNbFilleRedoublanteCM1(e.target.value)} className="form-control" />
     </div>
 </div>

 <div class="card mb-3">
    <div class="card-body">
        <h4>CM2</h4>
        <h6>nombre de garcon Inscrit a la rentree</h6>
        <input type="number" value={nbGarconRentreeCM2} onChange={(e) => setNbGaconRentreeCM2(e.target.value)} className="form-control" />
        <h6>nombre de filles inscrire a la rentree</h6>
        <input type="number" value={nbFilleRentreeCM2} onChange={(e) => setNbFilleRentreeCM2(e.target.value)} className="form-control" />
        <br />
        <h6>nombre de garcon redoublants</h6>
        <input type="number" value={nbGarconRedoublantCM2} onChange={(e) => setNbGarconeRedoublantCM2(e.target.value)} className="form-control" />
        <h6>nombre de filles redoublantes</h6>
        <input type="number" value={nbFilleRedoublanteCM2} onChange={(e) => setNbFilleRedoublanteCM2(e.target.value)} className="form-control" />
     
        <br /><br />
        <Button className="btn btn-primary" loading={loading} onClick={handleNext}>Suivant</Button>
     </div>
 </div>

 <br /><br />
</div>
        </>
    );
}