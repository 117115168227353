import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { useLocalStorage } from "../../../LocalStorageProvider";


export default function Start(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const { saveData, readData } = useLocalStorage(); // on se connecte au privider de la memoire local
    const statLevel = readData(); // on recupere les donnees de la memoire local

    useEffect(() => {

        if(statLevel && statLevel.isOk == false){ // si une etape n'est pas ok
            setMainView(statLevel.keyView); //on affiche la vue en question
        }
       
    },[]);
   
    return (
        <>
            <center>
                <h1>Choisissez le type de rapport </h1><br />
                <button className="btn btn-primary btn-lg">rentrées enseignement General</button>
                <br /><br />
                <button className="btn btn-success btn-lg" onClick={() => setMainView('enseignement_base')}>rentrées enseignement de base</button>
            </center>
        </>
    );
}