import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { APP_URL } from "../../../../URL";
import axios from "axios";


export default function LookSomeSchool(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(true);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
    const config = {
        headers: headers, 
        };
   
        const statLevel = readData(); // on recupere les donnees de la memoire local
    
    const [schools, setSchools] = useState([]);

        useEffect((e) => {

            if(statLevel && statLevel.isOk == false){ // si une etape n'est pas ok
                setMainView(statLevel.keyView); //on affiche la vue en question
            }

            axios.get(APP_URL+'user/datas-school',config).then((response) => {
                setSchools(response.data);
                console.log(response.data);
                setLoading(false);

            }).catch((error) => {
                console.log(error);
            });

           
        },[]);

       
       
  

    return (
        <>
            {loading?<>
            
                <center>
                    <br /><br />
                    <Spin size="large" />
                </center>
            
            </>:<>
            <div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Choisie une ecole (pour son rapport de rentrées) </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

                {schools.map((item) => (
                    <>
                        <a href="javascript:void(0);" onClick={() => {
                            if(item.categorie == 'Enseignement de base'){
                                setMainView('enseignement_base');
                            }
                            else if(item.categorie == 'Enseignement général'){
                                setMainView('enseignement_general');
                            } else alert('cet ecole n\'est encore lie a aucune categorie');
                        }} style={{color:'blue'}}><h4><span className="fa fa-home"></span> {item.denomination}({item.categorie})</h4></a>
                    </>
                ))}

    </div>
 </div>
</div>
            </>}
        </>
    );
}