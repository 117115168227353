import logo from './logo.svg';
// import './App.css';
import { BrowserRouter as Router,Route, Routes, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { useEffect } from 'react';
import { langState } from './atoms/AppAtome';
import { useRecoilValue } from 'recoil';
import Login from './components/Login';
import InitAtmin from './components/InitAdmin';
import Lookup from './components/Lookup';
import DashboadAdmin from './components/admin/Dashboadadmin';
import CreateUser from './components/admin/CreateUser';
import Dashboad from './components/user/Dashboad';
import Home from './components/Home';

const theme = {
  primaryColor: '#ff0000',
};

function App() {

  const { t } = useTranslation();
  const langStateValue = useRecoilValue(langState);

    useEffect(() => {
      i18n.changeLanguage(langStateValue);
    }, [langStateValue]);
 
  return (
   
    <Router>
    <Routes>
     
     {/* Home Route */}
      <Route path="/" exact element={<Home />} />
      <Route path='/inscription' element={<>Inscription ici</>} />
      <Route path='/login' element={<Login />} />
      <Route path='/init' element={<InitAtmin />} />
      <Route path='/lookup' element={<Lookup />} /> 

      {/* Admin */}
      <Route path='/admin' element={<DashboadAdmin />} /> 
     

      {/* user */}
      <Route path='/user' element={<Dashboad />} />
      
      <Route path='*' element={<>ERROR 404</>} />

    </Routes>

    <style jsx>{`
       
    `}</style>
</Router>
  );
}

export default App;
