import { Spin, notification } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { APP_URL } from "../URL";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";



export default function InitAtmin(){

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
   
    useEffect(() => {
        axios.post(APP_URL+'init',{
            'email':'admin@seduc.com',
            'roles':'ROLE_ADMIN',
            'password':'123456',
            'nom':'admin',
            'prenom':'admin',
            'phone':'0000'
        }).then((response) => {
            setLoading(false);

            // on natifie 
            notification.open({
                message:'Initialisation terminer',
                description: response.data.msg,
                placement:'bottomLeft',
                icon: <CheckOutlined />,
                color: 'red',
            })

            console.log(response.data);
            // on redirige
            return navigate('/lookup');
        }).catch((error) => {
            
            console.log(error);
        })
    },[]);

    return (
        <>
        {loading?<>

            <center>
                <br /><br/><br/><br/>
                <Spin size="large" />
            </center>

        </>:<>
        
        </>}
       
        </>
    );
}