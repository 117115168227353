import React, { createContext, useContext, useState, useEffect } from 'react';

// Créer un context pour le LocalStorageProvider
const LocalStorageContext = createContext();

// Créer le composant LocalStorageProvider
const LocalStorageProvider = ({ children }) => {
  // État local pour stocker les données
  const [data, setData] = useState(() => {
    const storedData = localStorage.getItem('myData');
    return storedData ? JSON.parse(storedData) : null;
  });

  // Mettre à jour le localStorage à chaque changement dans l'état 'data'
  useEffect(() => {
    localStorage.setItem('myData', JSON.stringify(data));
  }, [data]);

  // Exposer des méthodes pour enregistrer et lire les données
  const saveData = newData => {
    setData(newData);
  };

  const readData = () => {
    return data;
  };

  return (
    <LocalStorageContext.Provider value={{ saveData, readData }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

// Créer un hook pour utiliser le LocalStorageContext
const useLocalStorage = () => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useLocalStorage doit être utilisé à l\'intérieur de LocalStorageProvider');
  }
  return context;
};

export { LocalStorageProvider, useLocalStorage };
