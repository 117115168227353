import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState, repartitionEffectifIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Button } from "antd";


export default function Repartition_effectifs_trois_annees(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

    const [repartitionEffectifsId, setRepartitionEffectifsId] = useRecoilState(repartitionEffectifIdState);
    const [annee, setAnnee] = useState(null);
   
    const [nbGarconPS, setNbGarconPS] = useState(0);
    const [nbFillePS, setNbFillePS] = useState(0);
    
    const [nbGarconGS, setNbGarconGS] = useState(0);
    const [nbFilleGS, setNbFilleGS] = useState(0);

    const [nbGarconSIL, setNbGarconSIL] = useState(0);
    const [nbFilleSIL, setNbFilleSIL] = useState(0);
    
    const [nbGarconCP, setNbGarconCP] = useState(0);
    const [nbFilleCP, setNbFilleCP] = useState(0);
    
    const [nbGarconCE1, setNbGarconCE1] = useState(0);
    const [nbFilleCE1, setNbFilleCE1] = useState(0);

    const [nbGarconCE2, setNbGarconCE2] = useState(0);
    const [nbFilleCE2, setNbFilleCE2] = useState(0);

    const [nbGarconCM1, setNbGarconCM1] = useState(0);
    const [nbFilleCM1, setNbFilleCM1] = useState(0);

    const [nbGarconCM2, setNbGarconCM2] = useState(0);
    const [nbFilleCM2, setNbFilleCM2] = useState(0);

    useEffect(() => {

        axios.post(APP_URL+'esb/save-trois-annee',{
            'rentreer_esb' : datas.rentreer_esb,
        },config).then((response) => {
            setRepartitionEffectifsId(response.data.id)
            setAnnee(response.data.annee1);
        }).catch((error) => {
            console.log(error);
        })

    },[]);

    const handleNext = () => {
        
        axios.post(APP_URL+'esb/')
        setLoading(true);

    }


    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Repartition des effectifs des trois dernieres annees: annee {annee} </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

       <h4>PS</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconPS} onChange={(e) => setNbGarconPS(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFillePS} onChange={(e) => setNbFillePS(e.target.value)} />

        <h4>GS</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconGS} onChange={(e) => setNbGarconGS(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleGS} onChange={(e) => setNbFilleGS(e.target.value)} />

        <h4>SIL</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconSIL} onChange={(e) => setNbGarconSIL(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleSIL} onChange={(e) => setNbFilleSIL(e.target.value)} />

        <h4>CP</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconCP} onChange={(e) => setNbGarconCP(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleCP} onChange={(e) => setNbFilleCP(e.target.value)} />

        <h4>CE1</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconCE1} onChange={(e) => setNbGarconCE1(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleCE1} onChange={(e) => setNbFilleCE1(e.target.value)} />

        <h4>CE2</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconCE2} onChange={(e) => setNbGarconCE2(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleCE2} onChange={(e) => setNbFilleCE2(e.target.value)} />

        <h4>CM1</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconCM1} onChange={(e) => setNbGarconCM1(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleCM1} onChange={(e) => setNbFilleCM1(e.target.value)} />

        <h4>CE2</h4>
        <h6>Effectif garçon</h6>
        <input type="number" className="form-control" value={nbGarconCE2} onChange={(e) => setNbGarconCE2(e.target.value)} />
        <br />
        <h6>Effectif filles</h6>
        <input type="number" className="form-control" value={nbFilleCE2} onChange={(e) => setNbFilleCE2(e.target.value)} />

        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>


    </div>
  </div>
</div>
<br /><br /><br />
        </>
    );
}