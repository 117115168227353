import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function Infrastruture(){

  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [relationId, setRelationId] = useRecoilState(relationIdState);

  const {saveData,readData} = useLocalStorage();
  const datas = readData();
  const [loading, setLoading] = useState(false);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

  const [salleProf, setSalleProf] = useState('');
  const [laboratoireSVTouPCT, setLaboratoireSVTouPCT] = useState('');
  const [bibliotheque, setBiblioteque] = useState('');
  const [salleInformatique, setSalleInformatique] = useState('');
  const [atelierCablage, setAtelierCablage] = useState('');
  const [atelierDeMesure, setAtelierDeMesure] = useState('');
  const [atelierElectronique, setAtelierElectronique] = useState('');
  const [atelierFabricationMecanique, setAtelierFabricationMecanique] = useState('');
  const [atelierReparationAutomobile, setAtelierReparationAutomobile] = useState('');
  const [atelierC_A_T_S, setAtelierC_A_T_S] = useState('');
  const [atelierFroidClimatisation, setAtelierFroidClimatisation] = useState('');
  const [atelierMaconnerie, setAtelierMaconnerie] = useState('');
  const [salleDessin, setSalleDessin] = useState('');
  const [atelierMenuiserie, setAtelierMenuiserie] = useState('');
  const [atelierCouture, setAtelierCouture] = useState('');
  const [atelierCoupe, setAtelierCoupe] = useState('');
  const [atelierCuisine, setAtelierCuisine] = useState('');
  const [atelierPuericulture, setAtelierPuericulture] = useState('');
  const [atelierEconomieDomestique, setAtelierEconomieDomestique] = useState('');
  const [salleBureautique, setSalleBureautique] = useState('');
  const [infirmerie, setInfirmerie] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
   },[]);

  const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

      const handleSend = () => {
        setLoading(true);

        axios.post(APP_URL+'esg/save-infrastructure',{
          'salleProf':salleProf,
          'laboratoireSVTouPCT':laboratoireSVTouPCT,
          'bibliotheque':bibliotheque,
          'salleInformatique':salleInformatique,
          'atelierCablage':atelierCablage,
          'atelierDeMesure':atelierDeMesure,
          'atelierElectronique':atelierElectronique,
          'atelierFabricationMecanique':atelierFabricationMecanique,
          'atelierReparationAutomobile':atelierReparationAutomobile,
          'atelierC_A_T_S':atelierC_A_T_S,
          'atelierFroidClimatisation':atelierFroidClimatisation,
          'atelierMaconnerie':atelierMaconnerie,
          'salleDessin':salleDessin,
          'atelierMenuiserie':atelierMenuiserie,
          'atelierCouture':atelierCouture,
          'atelierCoupe':atelierCoupe,
          'atelierCuisine':atelierCuisine,
          'atelierPuericulture':atelierPuericulture,
          'atelierEconomieDomestique':atelierEconomieDomestique,
          'salleBureautique':salleBureautique,
          'infirmerie':infirmerie,
          'rentreer_esg' : datas.rentreer_esg
        },config).then((response) => {

          let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.infrastructure_id; // on enregistre la vue en cours
            localData.keyView = 'esg_autre_info';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre 

            // on notifie
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

              setMainView('esg_autre_info');

        }).catch((error) => {
          setLoading(false);
          console.log(error);
        })

      }

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
  <div class="card-body">
      <h2> Infrastruture </h2>
      <div className="alert alert-warning">
        Vous pouvez repondre par <b>Dur</b> , <b>Semi-dur</b>,<b>Mat Prov</b> ou laisser <b>Vide</b>
      </div>
  </div>
</div>
      <div class="card mb-3">
        
        <div class="card-body">

              <h6> Salle de professeurs ?</h6>
                <input type="text" value={salleProf} onChange={(e) => setSalleProf(e.target.value)} className="form-control" />
              
              <h6> Laboratoire PCT/SVT ?</h6>
                <input type="text" value={laboratoireSVTouPCT} onChange={(e) => setLaboratoireSVTouPCT(e.target.value)} className="form-control" />
                
              <h6> Bibliotheque ?</h6>
                <input type="text" value={bibliotheque} onChange={(e) => setBiblioteque(e.target.value)} className="form-control" />
              
              <h6> Salle d'Informatique ?</h6>
                <input type="text" value={salleInformatique} onChange={(e) => setSalleInformatique(e.target.value)} className="form-control" />
              
              <h6> Salle d'atelier Cablage ?</h6>
                <input type="text" value={atelierCablage} onChange={(e) => setAtelierCablage(e.target.value)} className="form-control" />
                
              <h6> Atelier De Mesures ?</h6>
                <input type="text" value={atelierDeMesure} onChange={(e) => setAtelierDeMesure(e.target.value)} className="form-control" />
              
              <h6> Atelier Electronique ?</h6>
                <input type="text" value={atelierElectronique} onChange={(e) => setAtelierElectronique(e.target.value)} className="form-control" />
                
              <h6> Atelier Fabrication Mecanique ?</h6>
                <input type="text" value={atelierFabricationMecanique} onChange={(e) => setAtelierFabricationMecanique(e.target.value)} className="form-control" />
              
              <h6> Atelier Reparation Automobile ?</h6>
                <input type="text" value={atelierReparationAutomobile} onChange={(e) => setAtelierReparationAutomobile(e.target.value)} className="form-control" />
                
              <h6> Atelier Chaudro Ajustage Tôlerie Soudure ?</h6>
                <input type="text" value={atelierC_A_T_S} onChange={(e) => setAtelierC_A_T_S(e.target.value)} className="form-control" />
                
              <h6> Atelier Froid et Climatisation </h6>
                <input type="text" value={atelierFroidClimatisation} onChange={(e) => setAtelierFroidClimatisation(e.target.value)} className="form-control" />
                
              <h6> atelier Maçonnerie </h6> 
                <input type="text" value={atelierMaconnerie} onChange={(e) => setAtelierMaconnerie(e.target.value)} className="form-control" />
                
              <h6> salle Dessin </h6> 
                <input type="text" value={salleDessin} onChange={(e) => setSalleDessin(e.target.value)} className="form-control" />
                
              <h6> atelier menuiserie </h6> 
                <input type="text" value={atelierMenuiserie} onChange={(e) => setAtelierMenuiserie(e.target.value)} className="form-control" />
                
            
              <h6> Atelier Couture </h6>
                <input type="text" value={atelierCouture} onChange={(e) => setAtelierCouture(e.target.value)} className="form-control" />
                
              <h6> atelier Coupe </h6>
                <input type="text" value={atelierCoupe} onChange={(e) => setAtelierCoupe(e.target.value)} className="form-control" />
                
              <h6> Atelier Cuisine </h6>
                <input type="text" value={atelierCuisine} onChange={(e) => setAtelierCuisine(e.target.value)} className="form-control" />
                
              <h6> atelier Puericulture </h6> 
                <input type="text" value={atelierPuericulture} onChange={(e) => setAtelierPuericulture(e.target.value)} className="form-control" />
                
              <h6> atelier Economie Domestique </h6> 
                <input type="text" value={atelierEconomieDomestique} onChange={(e) => setAtelierEconomieDomestique(e.target.value)} className="form-control" />
                
              <h6> salle Bureautique </h6> 
                <input type="text" value={salleBureautique} onChange={(e) => setSalleBureautique(e.target.value)} className="form-control" />
                
              <h6> infirmerie </h6> 
                <input type="text" value={infirmerie} onChange={(e) => setInfirmerie(e.target.value)} className="form-control" />
                
              <br />

              <Button loading={loading} onClick={handleSend} className="btn btn-primary">Suivant</Button>

        </div>
      </div>
</div>

          <br /><br /><br />
        </>
    );
}