import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../atoms/AppAtome";
import { useLocalStorage } from "../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../URL";
import { Spin } from "antd";


export default function RapportsRentreeList(){

    
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(true);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [schoolDatas, setSchoolDatas] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };


        useEffect(() => {
            axios.get(APP_URL+'user/datas-school',config).then((response) => {
                setLoading(false);
                setSchoolDatas(response.data);
                console.log('school list', response.data);
            }).catch((error) => {
                console.log(error)
            })
        },[]);


    return(
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Choisie une ecole (pour avoir la liste des rapports Enregistré)  </h2>
    </div>
</div>

<div class="card mb-3">
<div class="card-body">
        {loading?<>
           <center> <Spin size="large" /> </center> 
        </>:<>
          {schoolDatas.map((item) => (
            <>
               <a href="javascript:void(0);" onClick={() => setMainView('myenseigmentbase')}> <h3> <span className="fa fa-home"></span> {item.denomination} </h3> </a> 
            </>
          ))}
           
        </>}

        <br />
        
</div>
</div>
</div>
        </>
    );
}