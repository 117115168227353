import { useRecoilState } from "recoil";
import { mainViewState, rapportIdState, relationIdState, rentreeESBIdState } from "../../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../../LocalStorageProvider";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { APP_URL } from "../../../../../URL";
import { Button } from "antd";

export default function InfrastructureUpdate(){

  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [relationId, setRelationId] = useRecoilState(relationIdState);

  const {saveData,readData} = useLocalStorage();
  const datas = readData();
  const [loading, setLoading] = useState(true);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const [rapportId, setRapportId] = useRecoilState(rapportIdState);
  const [rentreeEsbId, setRentreeEsbId] = useRecoilState(rentreeESBIdState);
  let [compt, setCompt] = useState(0);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.seduc_token}`
    };
    
const config = {
    headers: headers, 
    };

  const handleSubmit = () => {
       console.log('submite here.. ');
  }

    const [infrastructures, setInfrastructures] = useState([]);
    const [superficie_terrain, setSuperficie_terrain] = useState('');
    const [superficieBatie, setSuperficieBatie] = useState(0);
    const [superficieReste, setSuperficieReste] = useState('');
    const [terraintitrer, setTerrainTitrer] = useState(false);
    const [proprietaireTerrain, setProprietaireTerrain] = useState('oui');
    const [coursSoir, setCoursSoir] = useState(false);
    const [nomCoursSoir, setNomCoursSoir] = useState('');
    const [nbSalleFonctionelle, setNbSalleFontionelle] = useState(0);
    const [nbSalleNonFonctionelle, setNbSalleNomFonctionelle] = useState(0);
    const [bibliotheque, setBiblioteque] = useState(false);
    const [nbOuvrage, setNbOuvrage] = useState(0);
    const [nbAireJeux, setNbAireJeux] = useState(0);
    const [superficie, setSuperficie] = useState('');
    const [cantineFonctionelle, setCantineFonctionelle] = useState(false);
    const [nbPointEau, setNbPointEaux] = useState(0);
    const [capacite, setCapacite] = useState('');
    const [nbEleveAbonnee, setNbEleveAbonnee] = useState('0');
    const [infirmerie, setInfirmerie] = useState(false);
    const [boitePharmatie, setBoitePharmatie] = useState(false);
    const [nbTableBanc, setNbTableBanc] = useState('0,0,0,0,0');
    const [nbTables, setNbTables] = useState('0,0');
    const [nbChaise, setNbChaise] = useState(0);
    const [branchementEauCourant, setBranchementEauCourant] = useState(false);
    const [electrifie, setElectrifie] = useState(false);
    const [nbToilettesModerne, setNbToilettesModerne] = useState(0);
    const [nbToilettesIndigene, setNbToilettesIndigene] = useState(0);
    const [cooperativeScolaire, setCoorperativeScolaire] = useState(false);
    const [titre, setTitre] = useState(false);

    useEffect(() => {
      axios.get(APP_URL+'user/infrastructure-list/'+rentreeEsbId,config).then((response) => {
        setInfrastructures(response.data);
        console.log(response.data); 
        response.data.map((item) => {
          setSuperficie_terrain(item.superficie_terrain);
          setSuperficieBatie(item.superficie_batie);

        })
       
      }).catch((error) => {
        console.log(error);
      });


    },[]);

    return(
        <>
 <div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Modifier les informations lies a cet infrastructure <a href="javascript:void(0)" className="btn btn-primary"> Retour a la liste </a> </h2>
    </div>
</div>

<div class="card mb-3">
  <div class="card-body">
      {infrastructures.map((item) => {  return( //46.105.51.36
        <>
        <label>Superficie du terrain</label>
          <input type="text" className="form-control" value={superficie_terrain} onChange={(e) => setSuperficie_terrain(e.target.value)} />
          <br />
          <label>Superficie Batie</label>
          <input type="number" className="form-control" value={superficieBatie} onChange={(e) => setSuperficieBatie(e.target.value)} />
          <br />
          <br />
          <Button onClick={handleSubmit} className="form-control">Modifier</Button>
        </>
      )} )}
  </div>
 </div>

<br /><br />
</div>
        </>
    );
}