
import axios from "axios";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { APP_URL } from "../URL";
import { Spin } from "antd";
import { loadLookupState } from "../atoms/AppAtome";

export default function Lookup(){
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const navigate = useNavigate();
    const [loadingLookup, setLoadingLookup] = useRecoilState(loadLookupState);

    const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${cookies.seduc_token}`
	  };
	
	const config = {
		headers: headers,
	  };

    useEffect(() => {

        setLoadingLookup(true);

        if(!cookies.seduc_token){
            return navigate('/login');
        }else{
           axios.get(APP_URL+'look-role',config).then((response) => {

              if(response.data.role == 'ROLE_ADMIN')  navigate('/admin');
              if(response.data.role == 'ROLE_ECOLE') navigate('/user'); 
           
           }).catch((error)=>{
                console.log(error);
           })

          
        }

    },[]);

    return (
      <>
        {loadingLookup?<>

        <center>
            <br /><br/><br /><br/><br/>
            <h1>
                <Spin size="large" />
            </h1>
        </center>

        </>:<>
        <br/><br /><br/><br/>
        </>}
        
      </>
    );
}