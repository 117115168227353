import { useState } from "react";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function EtablissementEnvironnant(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const [nomEtablissement, setNomEtablissement] = useState('');
    const [quartier, setQuartier] = useState('');
    const [nomFondateur, setNomFondateur] = useState('');
    const [contact, setContact] = useState('');
    

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

    const handleNext = () => {
        setLoading(true);

        axios.post(APP_URL+'esb/save-etablissement-environnement/'+datas.rentreer_esb,{
            'nomEtablissement':nomEtablissement,
            'quartier':quartier,
            'nomFondateur':nomFondateur,
            'contact':contact,
        },config).then((response) => {

            setLoading(false);

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'aspects_finanncier';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre
            console.log('REP',response.data);

            // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('aspects_finanncier');


        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Etablissements Environnants </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

        {/* <h4>PS</h4> */}
        <h6>Nom Etablissements</h6>
        <input type="text" className="form-control" value={nomEtablissement} onChange={(e) => setNomEtablissement(e.target.value)} />
        <br />
        <h6>Quartier</h6>
        <input type="text" className="form-control" value={quartier} onChange={(e) => setQuartier(e.target.value)} />
       
        <br />
        <h6>Nom fondateur</h6>
        <input type="text" className="form-control" value={nomFondateur} onChange={(e) => setNomFondateur(e.target.value)} />
        <h6>Contact</h6>
        <input type="text" className="form-control" value={contact} onChange={(e) => setContact(e.target.value)} />
        
        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>

    </div>
 </div>
</div>
<br /><br /><br />
        </>
    );
}