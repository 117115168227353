import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useState } from "react";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie";
import { CheckCircleOutlined } from "@ant-design/icons";



export default function Trans1(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);

    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const [nbPermanantHommeMaternelle, setNbPermanantHommeMaternelle] = useState(0);
    const [nbPermanantFemmeMaternelle, setNbPermanantFemmeMaternelle] = useState(0);
    const [nbVacataireHommeMaternelle, setNbVacataireHommeMaternelle] = useState(0);
    const [nbVacataireFemmeMaternelle, setNbVacataireFemmeMaternelle] = useState(0);

    const [nbPermanantHommePrimaire, setNbPermanantHommePrimaire] = useState(0);
    const [nbPermanantFemmePrimaire, setNbPermanantFemmePrimaire] = useState(0);
    const [nbVacataireHommePrimaire, setNbVacataireHommePrimaire] = useState(0);
    const [nbVacataireFemmePrimaire, setNbVacataireFemmePrimaire] = useState(0);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };

    const sending = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-repartition-enseignant',{
            'nbPermanantHommeMaternelle' : nbPermanantHommeMaternelle,
            'nbPermanantFemmeMaternelle' : nbPermanantFemmeMaternelle,
            'nbVacataireHommeMaternelle' : nbVacataireHommeMaternelle,
            'nbVacataireFemmeMaternelle' : nbVacataireFemmeMaternelle,

            'nbPermanantFemmePrimaire' : nbPermanantFemmePrimaire,
            'nbPermanantHommePrimaire' : nbPermanantHommePrimaire,
            'nbVacataireHommePrimaire' : nbVacataireHommePrimaire,
            'nbVacataireFemmePrimaire' : nbVacataireFemmePrimaire,
            'enseignement_id' : relationId,
        },config).then((response) => {

            // on enregistre l'id de l'enseignement de base dans la memoire local

            
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                      Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              })

            
              let localData  = readData(); // on recupere les donnees de la memoire local
              localData.keyView = 'infrastructure'; // on enregistre la vue en cours

              saveData(JSON.parse(JSON.stringify(localData)));

              setMainView('infrastructure');

        }).catch((error) => { 
            console.log(error);
            setLoading(false);
        })

    }


    return (
        <>
         <div class="container-fluid">
          <div class="card mb-3">
          <div class="card-body">
                <h2> Repartition des enseignants </h2>
          </div>
          </div>

          <div class="card mb-3">
          <div class="card-body">
                <h6>Nombre permanents d'Homme en Maternelle</h6>
                <input type="number" value={nbPermanantHommeMaternelle} onChange={(e) => setNbPermanantHommeMaternelle(e.target.value)} className="form-control" />
                <h6>Nombre permanents de Femmes en Maternelle</h6>
                <input type="number" value={nbPermanantFemmeMaternelle} onChange={(e) => setNbPermanantFemmeMaternelle(e.target.value)} className="form-control" />

                <h6>Nombre de Vacataire Hommes en Maternelle</h6>
                <input type="number" value={nbVacataireHommeMaternelle} onChange={(e) => setNbVacataireHommeMaternelle(e.target.value)} className="form-control" />
                <h6>Nombre de vacataire Femmes en Maternelle</h6>
                <input type="number" value={nbVacataireFemmeMaternelle} onChange={(e) => setNbVacataireFemmeMaternelle(e.target.value)} className="form-control" /> 

                <h6>Nombre permanents d'Homme en primaire</h6>
                <input type="number" value={nbPermanantHommePrimaire} onChange={(e) => setNbPermanantHommePrimaire(e.target.value)} className="form-control" />
                <h6>Nombre permanents de Femmes en primaire</h6>
                <input type="number" value={nbPermanantFemmePrimaire} onChange={(e) => setNbPermanantFemmePrimaire(e.target.value)} className="form-control" />
                
                <h6>Nombre de vacataire Hommes en primaire</h6>
                <input type="number" value={nbVacataireHommePrimaire} onChange={(e) => setNbVacataireHommePrimaire(e.target.value)} className="form-control" />
                <h6>Nombre de vacataire Femmes en primaire</h6>
                <input type="number" value={nbVacataireFemmePrimaire} onChange={(e) => setNbVacataireFemmePrimaire(e.target.value)} className="form-control" />
                <br />
                <Button loading={loading} onClick={sending} className="btn btn-primary" > Suivant </Button>
          </div>
          </div>
            <br /><br /><br />
         </div>
        </>
    );
}