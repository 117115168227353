import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../atoms/AppAtome";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";

export default function EcoleList(){
  const [mainView, setMainView] = useRecoilState(mainViewState);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

  const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${cookies.seduc_token}`
	  };
	
	const config = {
		headers: headers,
	  };


  useEffect(() => {
    window.document.title = 'creer un nouvel utilisateur';
    axios.get(APP_URL+'admin/users-list',config).then((response) => {
      setLoading(false);
      setUsers(response.data);
      console.log(response.data);
    }).catch((error) => {
      console.log(error);
    })

  },[]);

    return (
        <>
      <div class="container-fluid">
     
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Dashboard</a>
        </li>
        <li class="breadcrumb-item active">utilisateur</li>
      </ol>
		
      <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-table"></i> Liste des utilisateurs  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <Link to={'javascript:void(0)'} onClick={() => setMainView('createUser')} className="btn btn-primary">Creer un nouvel utilisateur <span className="fa fa-user-plus"></span></Link>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            {loading?<>
              
              <center>
               <br /><br /><br />
                <Spin size="large" />
                <br /><br /><br />
              </center>
            </>:<>
            
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tfoot>
                <tr>

                  <th>Nom</th>
                  <th>Prenom</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Options</th>
                </tr>
              </tfoot>
              <tbody>
               
                  
                  {users.map((item) =>  (
                    <>

                       <tr>
                        <td>{item.nom}</td>
                        <td>{item.prenom}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>...</td>
                       </tr>
                       
                    </>
                   
                   )
                  
                  )}
                 
               </tbody>
            </table>

            
            </>}

          </div>
        </div>
        <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
      </div>
	 
	  </div>
	 
        </>
    );
}