import { useState } from "react";
import { useRecoilState } from "recoil";
import { aspectsFinanciersIdState, mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import { Button, notification } from "antd";
import axios from "axios";
import { APP_URL } from "../../../../URL";
import { CheckCircleOutlined } from "@ant-design/icons";


export default function Recette(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const [aspectFinancierId, setAspectFinancierid] = useRecoilState(aspectsFinanciersIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();

    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

    const [fraisInscription, setFraiInscription] = useState('');
    const [ecolage, setEcolage] = useState('');
    const [subvention, setSubvention] = useState('');
    const [autreResource, setAutreResource] = useState('');

    const [investissement, setInvestissement] = useState('');
    const [fonctionnement, setFonctionnement] = useState('');

    const handleNext = () => {
        setLoading(true);
        axios.post(APP_URL+'esb/save-recette/'+datas.rentreer_esb,{
            'fraisInscription':fraisInscription,
            'ecolage':ecolage,
            'subvention':subvention,
            'autreResource':autreResource,
            'investissement':investissement,
            'fonctionnement':fonctionnement
        },config).then((response) => {

            let localData  = readData(); // on recupere les donnees de la memoire local
            localData.relationId = response.data.repartition_effectif_id; // on enregistre la vue en cours
            localData.keyView = 'etat_nominatif';
            saveData(JSON.parse(JSON.stringify(localData))); // on enregistre

            setAspectFinancierid(response.data.datas.aspectFinancierId);
            
             // on notifie 
          notification.open({
            message:(
               <div style={{ color: 'white' }}>
                 Enregistrer avec success
               </div>
              ),
            description: '',
            placement:'bottomLeft', 
            icon: <CheckCircleOutlined />,
            style: {
                backgroundColor: 'green', // Couleur de fond personnalisée
                color:'white',
                // Autres styles personnalisés...
              },
          })

          setMainView('etat_nominatif');

        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Contributions exigibles deja versees (montants) </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">
        <h2>Recette</h2>
        <h6>Frais d'inscription (Nombre d'eleve x taux d'inscription)</h6>
        <input type="text" className="form-control" value={fraisInscription} onChange={(e) => setFraiInscription(e.target.value)} />
        <br />

        <h6>Ecolage (Nombre d'eleve x taux d'ecolage)</h6>
        <input type="text" className="form-control" value={ecolage} onChange={(e) => setEcolage(e.target.value)} />
        <br />

        <h6>Subvention</h6>
        <input type="text" className="form-control" value={subvention} onChange={(e) => setSubvention(e.target.value)} />
        <br />

        <h6>Autre resources</h6>
        <input type="text" className="form-control" value={autreResource} onChange={(e) => setAutreResource(e.target.value)} />
        <br />
        <hr />

        <h2>Depense</h2>

        <h6>Investissement (ex: Achat de bancs etc)</h6>
        <textarea className="form-control" value={investissement} onChange={(e) => setInvestissement(e.target.value)}></textarea>
        <br />

        <h6>fonctionnement (ex: Achat des papiers, salaire des personnels etc)</h6>
        <textarea className="form-control" value={fonctionnement} onChange={(e) => setFonctionnement(e.target.value)}></textarea>
        <br />

        <br />
        <Button className="btn btn-primary" onClick={handleNext} loading={loading}>Suivant</Button>

    </div>
 </div>
</div>
<br /><br /><br />
        </>
    );
}