import axios from "axios";
import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useCookies } from "react-cookie"; 
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";

export default function Intro(){

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
    const {saveData,readData} = useLocalStorage();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

    
    useEffect(() => {

        axios.post(APP_URL+'user/save-rapport',{'type':'rentree_esg'},config).then((response) => {
           
            setLoading(false);
            setRelationId(response.data.rentree_esb_id); // ici on passe l'id de la table rentree_esb dans un state global pour pouvoir recuperer apres
            
            saveData({
                'rentreer_esg':response.data.rentree_esb_id,
                'detailProcess' : '',
                'relationId': null,
                'isOk' : false,
                'keyView' : 'etape1_esg_infrastructure',
            }); // on enregistre les donnees comme on aura besoin dans la base de donnee.

            setMainView('etape1_esg_infrastructure');
            
        }).catch((error) => {
            console.log(error);
        })

    },[]);

    return(
        <>
            <center>
                <br /><br /><br /><br />
                {loading?<>
                    <Spin size="large" />
                </>:<>
                    ...
                </>}

            </center>
        </>
    );
}