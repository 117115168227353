import { Button, Modal, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../atoms/AppAtome";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";
import { CheckCircleOutlined } from "@ant-design/icons";

export default function SchoolList(){
  const [mainView, setMainView] = useRecoilState(mainViewState);
 
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const [ecoles, setEcoles] = useState([]);
  const [haveSchool, setHaveSchool] = useState(false);
  const [thisData, setThisData] = useState({});

  const [dataSchool, setDataSchool] = useState([]);
  const [loadingDataSchool, setLoadingDataSchool] = useState(true);

  const [ecoleId, setEcoleId] = useState(0);

  const headers = {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${cookies.seduc_token}`
	  };
	
	const config = {
		headers: headers,
	  };

    const [denomination, setDenomination] = useState('');
    const [type, setType] = useState('Bilingue');
    const [langue, setLangue] = useState('');
    const [localite, setLocalite] = useState('');
    const [quartier, setQuartier] = useState('');
    const [telFondateur, setTelFondateur] = useState('');
    const [telEcole, setTelEcole] = useState('');
    const [telPrincipal, setTelPrincipal] = useState('');
    const [nomFondateur, setNomFondateur] = useState('');
    const [nomPrincipal, setnomPrincipal] = useState('');
    const [numeroAutorisation, setNumeroAutorisation] = useState('');
    const [categorie, setCategorie] = useState('Enseignement général');
    const [dateOuverture, setDateOuverture] = useState('');
   
  const [open, setOpen] = useState(false);

  const handleOk = () => {

    setLoading2(true);

    axios.post(APP_URL + 'user/update-school',{
      'denomination' : denomination,
      'type' : type,
      'langue' : langue, 
      'localite' : localite,
      'quartier' : quartier,
      'telFondateur' : telFondateur,
      'telEcole' : telEcole,
      'telPrincipal' : telPrincipal,
      'numeroAutorisation' : numeroAutorisation,
      'dateOuverture' : dateOuverture,
      'categorie': categorie,
      'ecole_id' : ecoleId,
    },config).then((response) => {
       setLoading2(false);
       setOpen(false);
       
      //  on envoi la notification
      notification.open({
        message:(
           <div style={{ color: 'white' }}>
             Enregistrer avec success
           </div>
          ),
        description: '',
        placement:'bottomLeft', 
        icon: <CheckCircleOutlined />,
        style: {
            backgroundColor: 'green', // Couleur de fond personnalisée
            color:'white',
            // Autres styles personnalisés...
          },
      })


    }).catch((error) => {
      console.log(error);
    })
   
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.document.title = 'creer un nouvel utilisateur';

    axios.get(APP_URL+'user/datas-school',config).then((response) => {
      setLoading(false);
      setEcoles(response.data);
      setErrorMsg('');
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      setErrorMsg('Une Erreur s\'est produite. actualiser la page ou contacter nous');
    })

    axios.get(APP_URL+'user/look-have-school').then((response) => {
       setHaveSchool(response.data.bool);
    }).catch((error) => {
       console.log(error);
    })

  },[loading2]);

    return (
        <>
      <div class="container-fluid">
     
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Dashboard</a>
        </li>
        <li class="breadcrumb-item active">utilisateur</li>
      </ol>
		
      <div class="card mb-3">
        <div class="card-header">
          <i class="fa fa-table"></i> Mes écoles  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
            <Link to={'javascript:void(0)'} onClick={() => setMainView('createMySchool')} className="btn btn-primary">Creer une ecole <span className="fa fa-home"></span></Link>
         
        </div>
        <div class="card-body">
          <div class="table-responsive">
            {loading?<>
              
              <center>
               <br /><br /><br />
                <Spin size="large" />
               
                <br /><br /><br />
              </center>
            </>:<>
            <h3><p style={{color:'red'}}>{errorMsg}</p></h3>
            <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>Denomination</th>
                  <th>type</th>
                  <th>Langage</th>
                  <th>Localite</th>
                  <th>Tel fondateur</th>
                  <th>Nom fondateur</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                <th>Denomination</th>
                  <th>type</th>
                  <th>Langage</th>
                  <th>Localite</th>
                  <th>Tel fondateur</th>
                  <th>Nom fondateur</th>
                  <th>Options</th>
                </tr>
              </tfoot>
              <tbody>

                  {ecoles.map((item) =>  (
                    <>

                       <tr>
                        <td>{item.denomination}</td>
                        <td>{item.type}</td>
                        <td>{item.langue}</td>
                        <td>{item.localite}</td>
                        <td>{item.telFondateur}</td>
                        <td>{item.nomFondateur}</td>
                        <td>
                          <button className="btn btn-warning" onClick={() => {
                            setOpen(true); // on ouvre la boite de dialogue
                            // on effectue la requette pour recuperer les informations de l'ecole encour
                            
                            setLoading3(true);

                            axios.post(APP_URL + 'user/get-one-school',{'ecole_id':item.id},config).then((response) => {
                              console.log(response.data);
                              setLoading3(false);

                              setDenomination(response.data.denomination);
                              setType(response.data.type);
                              setLangue(response.data.langue);
                              setLocalite(response.data.localite);
                              setQuartier(response.data.quartier);
                              setTelEcole(response.data.telEcole);
                              setTelFondateur(response.data.telFondateur);
                              setTelPrincipal(response.data.telPrincipal);
                              setNomFondateur(response.data.nomFondateur);
                              setnomPrincipal(response.data.nomPrincipal);
                              setNumeroAutorisation(response.data.numeroAutorisation);
                              setDateOuverture(response.data.dateOuverture);
                              setEcoleId(response.data.id);
                              setCategorie(response.data.categorie);
                              
                            }).catch((error) => { 
                              console.log(error); // on affiche l'erreur ici..
                            })

                          }}>Modifier <span className="fa fa-pencil"></span></button>
                        </td>
                       </tr>
                        
                    </>
                   
                   )
                  
                  )}
                 
               </tbody>
            </table>
            
            </>}

          </div>
        </div>
        <div class="card-footer small text-muted">Updated yesterday at 11:59 PM</div>
      </div>

      <Modal
        open={open}
        title="Modifier les information d'une ecole"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
         loading3 ? <> </> :
          <Button key="submit" type="primary" loading={loading2} onClick={handleOk}>
            Submit
          </Button>,
         
        ]}
      >
        {loading3?<>
            <center>
              <Spin size="large" />
            </center>
        </>:<>
            <form method="post">
              <label>Auteur</label>
              <input type="text" value={'Vous'} disabled className="form-control" />
              <label>Denomination</label>
              <input type="text" className="form-control" value={denomination} onChange={(e) => setDenomination(e.target.value)} />
              <label>type</label>
              <select className="form-control" value={type} onChange={(e) => setType(e.target.value)}>
                <option value={'Bilingue'}>Bilingue</option>
                <option value={'non bilingue'}>Non Bilingue</option>
              </select>

              <label>Categorie</label>
            <select className="form-control" value={categorie} onChange={(e) => setCategorie(e.target.value)}>
              <option value={'Enseignement général'}>Enseignement général</option>
              <option value={'Enseignement de base'}>Enseignement de base</option>
            </select><br />

              <label>Langue</label>
              <input type="text" className="form-control" value={langue} onChange={(e) => setLangue(e.target.value)} />

              <label>Localite</label>
              <input type="text" className="form-control" value={localite} onChange={(e) => setLocalite(e.target.value)} />

              <label>Quartier</label>
              <input type="text" className="form-control" value={quartier} onChange={(e) => setQuartier(e.target.value)} />

              <label>Tel fondateur</label>
              <input type="text" className="form-control" value={telFondateur} onChange={(e) => setTelFondateur(e.target.value)} />

              <label>Tel ecole</label>
              <input type="text" className="form-control" value={telEcole} onChange={(e) => setTelEcole(e.target.value)} />

              <label>Tel Principal</label>
              <input type="text" className="form-control" value={telPrincipal} onChange={(e) => setTelPrincipal(e.target.value)} />

              <label>Nom fondateur</label>
              <input type="text" className="form-control" value={nomFondateur} onChange={(e) => setNomFondateur(e.target.value)} />

              <label>Nom Principal</label>
              <input type="text" className="form-control" value={nomPrincipal} onChange={(e) => setnomPrincipal(e.target.value)} />

              <label>Numero Authorization</label>
              <input type="number" className="form-control" value={numeroAutorisation} onChange={(e) => setNumeroAutorisation(e.target.value)} />

              <label>Date Ouverture</label>
              <input type="date" value={dateOuverture} onChange={(e) => setDateOuverture(e.target.value)} className="form-control" />

            </form>
        </>}
      </Modal>
	 
	  </div>
	 
        </>
    );
}