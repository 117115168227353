// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({

  resources: {
    en: {
      translation: {
        // Vos clés et traductions en anglais
        welcome: 'Welcome to My App',
        login: 'Login',
        username: 'username',
        password: 'password',
        selectLanguage: 'select Language',
        loginButton: 'login'
      },
    },
    fr: {
      translation: {
        // Vos clés et traductions en français
        loginButton: 'connexion',
        welcome: 'Bienvenue sur Mon Application',
        login:'Connexion',
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',
        selectLanguage:'Choisie une langue'
      },
    },
  },
  lng: 'fr', // Langue par défaut
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false, // Pas d'échappement HTML dans les traductions
  },
});

export default i18n;
